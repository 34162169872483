.container {
  margin: 50px;
  background-color: var(--grey-background);
  text-align: center;
  height: 400px;
  width: 400px;
  position: relative;
}

.productImage {
  height: 75%;
}

.productTitle {
  position: relative;
  opacity: 100%;
  font-weight: bold;
  font-size: 20px;
}

.descriptionOverlay {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.15s ease-out;
  transform-origin: bottom;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
}

.descriptionOverlayForeground {
  position: absolute;
  background: var(--primary-color);
  opacity: 50%;
  width: 100%;
  height: 100%;
}

.descriptionText {
  position: absolute;
  height: fit-content;
  padding-bottom: 20px;
  margin: 20px;
  color: white;
}

.scrollContainer {
  position: relative;
  overflow: scroll;
  height: 75%;
}

/* remove scrollbars */

.scrollContainer {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scrollContainer::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.decoration {
  position: absolute;
  height: 100%;
  z-index: -1;
  transition: all 0.15s ease-out;
}

@media screen and (max-width: 500px) {
  .container {
    height: 300px;
    width: 300px;
    margin: 25px;
  }

  .productTitle {
    font-size: 15px;
  }
}