.container {
  margin: 0;
}

header {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  margin-left: 20vw;
  margin-right: 20vw;
  font-size: 1.2rem;
}

.navItem {
  text-align: center;
  max-height: 1.5rem;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 20px;
}

.navTitle {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 0.5em;
  padding-left: 0.5em;
  flex-direction: column;
}

.shade {
  background-color: var(--primary-color);
  height: 50%;
  width: 100%;
  position: relative;
  bottom: 60%;
  transform: scale(100%, 0);
  opacity: 20%;
  transition: 0.2s ease-in-out;
  transform-origin: top;
}

.navItem:hover .shade,
.navItem.active .shade{
  transform: scale(100%, 100%);
}

.logoContainer {
  height: 100%;
  text-align: center;
}

.logo {
  margin: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  height: 70%;
}

#burgerNav {
  position: absolute;
  right: 10px;
  height: 30px;
  width: 50px;
  margin: 10px;
  display: none;
}

#burgerNav > .stripe {
  height: 5px;
  margin-top: 5px;
  background-color: var(--primary-color);
}

#nav.responsive {
  right: 0;
}

#darkenContent {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 90;
  transition: opacity 0.2s linear;
  pointer-events: none;
}

#darkenContent.active {
  transition-delay: 0.1s;
  opacity: 0.7;
  pointer-events: all;
}

footer {
  box-sizing: border-box;
  background-color: var(--grey-background);
  width: 100%;
  padding: 20px;
  bottom: 0;
}

.footerContent {
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socialLogo {
  margin-left: 15px;
  margin-right: 15px;
  aspect-ratio: 1 / 1;
  height: 50px;
}

@media screen and (max-width: 768px) {
  #nav {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    position: fixed;
    right: -300px;
    top: 0;
    width: 300px;
    height: calc(100vh - 100px);
    background: white;
    margin: 0;
    padding: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 99;
    transition: right 0.2s ease-out;
    overflow: auto;
  }
  .navItem {
    padding-left: 20px;
    margin-bottom: 20px;
    /* transition: overflow .2s ease-in-out; */
    /* overflow: visible; */
    max-height: fit-content;
  }
  .navTitle {
    text-align: left;
  }
  .headerDivider {
    display: none;
  }
  #burgerNav {
    display: block;
  }
  header {
    flex-direction: row;
    justify-content: center;
  }

  .shade {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  #nav {
    width: 75vw;
  }
}
