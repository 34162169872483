.container {
	margin-top: 20px;
	flex-direction: column;
	display: flex;
	box-sizing: border-box;
	color: #182160;
	justify-content: center;
	align-items: center;
}

.title {
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: bold;
	text-align: center;
	box-sizing: border-box;
	color: #182160;
}

.description {
	font-size: 1.5rem;
	width: 50%;
	padding: 1.7rem;
	box-sizing: border-box;
	text-align: left;
	background: var(--grey-background)
}

.descriptionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-left: 200px;
	margin-right: 200px;
	margin-top: 100px;
	margin-bottom: 100px;
}

.filler {
	width: 35%;
}

@media screen and (max-width: 1500px) {
	.descriptionContainer {
		flex-direction: column;
		margin-left: 25px;
		margin-right: 25px;
	}

	.filler {
		width: 40%;
		margin-bottom: 100px;
	}

	.description {
		width: 70%;
	}
}

@media screen and (max-width: 600px) {
	.filler {
		width: 80%;
	}

	.description {
		width: 100%;
	}
}