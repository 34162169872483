.slideshow {
  width: 100%;
  position: relative;
}

.slideshowControl {
  color: white;
  font-size: 5vw;
  text-align: center;
  text-shadow: 1px 1px grey;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
  margin: auto auto auto auto;
  display: flex;
  align-items: center;
}

.slideshowControl > * {
  filter: drop-shadow(1px 1px grey);
}

.slide {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}

.slide.visible {
  opacity: 1;
  pointer-events: all;
}

#slideshowPrev {
  left: 10px;
}

#slideshowNext {
  right: 10px;
}

.slideshow .slide:not(:first-child) {
  position: absolute;
  top: 0;
}

.slideBackground {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 2.28/1;
}

#slideshowIndicator {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}

.slideshowIndicatorDot {
  color: lightgrey;
  padding-left: 5px;
  padding-right: 5px;
  transition: color 0.2s ease-in-out;
}

.slideshowIndicatorDot.active,
.slideshow-indicator-dot:hover {
  color: white;
}

.slideTextContainer {
  flex-direction: vertical;
  align-items: center;
  display: flex;
  position: absolute;
  top: 15%;
  bottom: 15%;
  right: 15%;
  left: 15%;
  margin: auto auto auto auto;
}

.slideTextContainer.positionLeft {
  justify-content: flex-start;
}

.slideTextContainer.positionRight {
  justify-content: flex-end;
}

.slideText {
  color: white;
  font-size: 4vw;
  text-align: center;
  width: 60%;
  text-shadow: 0.5px 0.5px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideLink > a {
  font-size: 3vw;
  color: white;
  text-decoration: none;
}
