.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 2.25rem;
}

.subheading {
  text-align: center;
}

.aboutTextContainer {
  font-size: larger;
  width: 40%;
  text-align: center;
  margin: 15px;
}

.picSection {
  margin: 25px;
  display: flex;
  width: 60%;
  justify-content: space-around;
}

.picSection > img {
  min-width: 500px;
  max-height: 500px;
}

.picSection > .aboutTextContainer {
  margin: 0;
  box-sizing: border-box;
  background-color: var(--grey-background);
  max-width: 500px;
  min-width: 500px;
  max-height: 500px;
  height: 500px;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: left;
  /* aspect-ratio: 1 / 1; */
}

@media screen and (max-width: 768px) {
  .subheading {
    margin-left: 20px;
    margin-right: 20px;
  }

  .picSection {
    flex-direction: column;
  }
  .picSection > img {
    min-width: 325px;
    max-height: 325px;

    margin-top: 25px;
    margin-bottom: 25px;
  }
  .picSection > .aboutTextContainer {
    max-width: 325px;
    min-width: 325px;
    width: 325px;
    max-height: unset;
    height: unset;
    padding: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .picSection {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-around;
    margin: 0;
  }

  .aboutTextContainer {
    width: 80%;
  }
}
