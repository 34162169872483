.dropdown {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  z-index: 90;
  scale: 100% 0%;
  transform-origin: top;
  transition: scale 0.2s ease-out;
  pointer-events: none;
}

.dropdown.active {
  scale: 100% 100%;
  pointer-events: all;
}

@media screen and (max-width: 768px) {
  .dropdown {
    position: static;
    transition: max-height 0.2s ease-in-out;
    max-height: 0;
    scale: unset;
    pointer-events: all;
  }
  .dropdown.active {
    max-height: 1000vh;
    scale: unset;
  }
}