.productGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: auto;
}

@media screen and (max-width: 1600px) {
  .productGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .productGrid {
    grid-template-columns: 1fr;
  }
}