@import url("https://fonts.cdnfonts.com/css/century-gothic");

body {
  --primary-color: #0b2659;
  --grey-background: #f7f7f6;
  color: var(--primary-color);
	font-family: 'Century Gothic', sans-serif;
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
