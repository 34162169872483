.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  padding: 0 100px 0 100px;
  cursor: default;
  background-color: var(--primary-color);
  /* height: 820px; */
  color: white;
}

.menuSection {
  /* for my fucking balls */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  padding-right: 50px;
  padding-left: 50px;
}

.menuSection:not(:last-child) {
  border-right: 2px solid white;
}

.optionList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  line-height: 1.7;
  font-size: 0.7cm;
}

.optionList > li {
  transition: background-color 0.3s, color 0.3s;
  padding: 5px;
}

/* only for desktop */
@media screen and (min-width: 768px) {
  .optionList > li:hover {
    background-color: white;
    color: var(--primary-color);
  }
}

/* mobile shitfuckery */
@media screen and (max-width: 768px) {
  .container {
    box-sizing: border-box;
    height: auto;
    width: 250px;
    background-color: white;
    color: var(--primary-color);
    flex-direction: column;
    padding: 0;
  }

  .optionList {
    font-size: medium;
  }

  .menuSection > h1 {
    white-space: normal;
    width: 200px;
    text-align: start;
    font-size: medium;
  }
}

